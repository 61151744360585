@import url("https://fonts.googleapis.com/css2?family=Karla:wght@400;500;600;700;800&display=swap");

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  box-sizing: border-box;
  font-size: 1.6rem;
  font-weight: 400;
  font-family: "Karla", sans-serif;
  line-height: 1.5;
  color: #555;

  background-color: #f7f7f7;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mb-small {
  margin-bottom: 2.4rem;
}

.separator {
  margin-bottom: 4.8rem;
}

.text-success {
  color: #448c04 !important;
}

.text-pending {
  color: #f9a21b !important;
}

.text-failed {
  color: #f91b1b !important;
}

.text-info {
  color: #228be6 !important;
}

.text-center {
  text-align: center !important;
}

.txt-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.8rem;
}

.txt-header2 {
  display: flex;
  align-items: center;
  gap: 1.8rem;

  margin-bottom: 2.4rem;
}

.txt-header2 span {
  cursor: pointer;
}

.txt-header2 ion-icon {
  display: block;
}

.txt-header2 h3 {
  flex: 1;
  text-align: center;
}

.flex {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.link {
  color: #397305;
  text-decoration: underline;
  cursor: pointer;
}

.link-1 {
  color: #397305;
  cursor: pointer;
}

.icon-block {
  width: 5.8rem;
  height: 5.8rem;
  border-radius: 50%;
  background-color: #ceddc0;

  margin-bottom: 1.6rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.icon-block div {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: 50%;
  background-color: #397305;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-block div ion-icon {
  width: 2.4rem;
  height: 2.4rem;
  display: block;
  color: #fff;
}

.heading {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1.8rem;
}

.search-form {
  height: 3.6rem;
  background-color: #fff;
  border: 1px solid #eaeaea;
  width: 25rem;

  display: flex;
  align-items: center;
}

.search-form button {
  display: block;
  width: 3.6rem;
  border: none;
  background-color: transparent;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search-form button ion-icon {
  width: 1.6rem;
  height: 1.6rem;
  color: #808080;
  display: block;
}

.search-form input {
  color: #808080;
  font-family: "Karla", sans-serif;
  background-color: transparent;
  outline: none;
  margin-right: 6px;
  font-size: 1.4rem;
  border: none;
  min-width: 0;
  flex: 1;
}

.auth {
  position: absolute;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  padding: 0 2.4rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.auth__main {
  width: 45rem;
  max-width: 100%;
  background-color: #fff;
  padding: 2.4rem 3.6rem;
  border-radius: 1.2rem;
}

.auth__img-block {
  text-align: center;
  margin-bottom: 1.8rem;
}

.auth__img {
  display: inline-block;
  height: 6rem;
  cursor: pointer;
}

.auth__heading {
  font-size: 2.2rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 0.6rem;
}

.auth__heading-subtext {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.auth__form {
  padding: 2.4rem 0;

  display: flex;
  flex-direction: column;
  gap: 1.8rem;
}

.auth__footer {
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  margin-top: 4.2rem;
}

.auth__footer-link {
  text-align: right;
}

.auth__footer-link a:link,
.auth__footer-link a:visited {
  color: #397305;
  text-decoration: underline;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}

.form-label {
  font-size: 1.4rem;
}

.form-bottom-label {
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 1.2rem;
}

.select-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1.2rem;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid #eaeaea;
  border-radius: 0.8rem;
  cursor: pointer;
}

.form-input-group {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid #eaeaea;
  border-radius: 0.8rem;
  overflow: hidden;
}

.form-input-group input {
  flex: 1;
  min-width: 0;
  border: none;
  background-color: transparent;
  outline: none;
  font-family: inherit;
  font-size: inherit;
}

.form-input-group button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.form-input-group button ion-icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  color: #777;
}

.form-input,
.form-select {
  display: block;
  width: 100%;
  height: 4.8rem;
  padding: 0 1.6rem;
  border: 1px solid #eaeaea;
  background-color: transparent;
  font-family: inherit;
  border-radius: 0.8rem;
  outline: none;
  font-size: inherit;
}

.form-select {
  -moz-appearance: none; /* Firefox */
  -webkit-appearance: none; /* Safari and Chrome */
  appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='grey' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 96%;
  background-position-y: 50%;
}

.button {
  display: block;
  width: 100%;
  text-align: center;
  height: 5.2rem;
  border-radius: 0.8rem;
  background-color: #e0e0e0;
  color: #828282;
  font-family: inherit;
  border: none;
  font-size: inherit;
  cursor: pointer;
}

.button--active {
  background-color: #397305;
  color: #fff;
}

.header {
  background-color: #fff;
  height: 6.5rem;
  box-shadow: 0px 4px 45px rgba(154, 154, 154, 0.1),
    0px 20px 200px rgba(57, 23, 119, 0.05);
  z-index: 9;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.header__container {
  max-width: 140rem;
  margin: auto;
  padding: 0 2.4rem;
  height: 100%;

  display: flex;
  align-items: stretch;
  justify-content: space-between;
  /* justify-content: center; */
}

.app-brand {
  height: 100%;

  display: flex;
  align-items: center;
  cursor: pointer;
}

.app-brand img {
  height: 5rem;
}

.header__btn {
  align-self: center;
  height: 4.8rem;
  font-size: inherit;
  background-color: #397305;
  color: #fff;
  border: none;
  font-family: inherit;

  display: flex;
  align-items: center;
  gap: 6px;

  padding: 0 1.6rem;
  border-radius: 6px;
  cursor: pointer;
}

.header__notification {
  align-self: center;
  position: relative;
  cursor: pointer;
}

.header__notification ion-icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  color: #397305;
}

.header__notification-number {
  position: absolute;
  top: 0;
  right: 0;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  width: 1.4rem;
  height: 1.4rem;

  border-radius: 50%;
  background-color: red;
  color: #fff;
  font-size: 10px;

  transform: translate(50%, -50%);
}

.main {
  max-width: 130rem;
  margin: auto;
  padding: 10.1rem 2.4rem 3.6rem;

  min-height: calc(100vh - 15rem);
}

.footer {
  height: 15rem;
  background-image: url(./assets/images/footer.png);
  background-size: cover;

  padding-top: 3.8rem;

  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 16px;
}

.user-welcome {
  display: flex;
  align-items: center;
  gap: 1.2rem;
  flex-wrap: wrap;

  margin-bottom: 2.4rem;
}

.user-welcome__img {
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  object-fit: cover;
}

.user-welcome__text {
  font-size: 1.6rem;
  margin-right: auto;

  display: flex;
  align-items: center;
  gap: 6px;
}

.user-welcome__text span {
  font-weight: 600;
}

.wallet {
  min-height: 32rem;
  border-radius: 2.4rem;
  overflow: hidden;

  background-image: url(./assets/images/bg-wallet.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;

  padding: 0 12.8rem;
  color: #fff;

  position: relative;
}

.wallet__heading {
  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.wallet__show-hide {
  cursor: pointer;
}

.wallet__show-hide ion-icon {
  display: block;
}

.wallet__balance {
  font-size: 6.4rem;
  font-weight: 500;
  cursor: pointer;
}

.pay-btn {
  display: flex;
  align-items: center;
  gap: 1.6rem;

  height: 9.6rem;
  font-size: 3.2rem;
  padding: 0 5.2rem;
  border-radius: 1.2rem;
  border: none;
  background-color: #e8fcd812;
  font-family: inherit;
  color: inherit;
  cursor: pointer;
}

.table-responsive {
  overflow-x: scroll;
}

.table-responsive::-webkit-scrollbar {
  display: none;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table thead tr {
  background-color: transparent;
}

.table tr {
  background-color: #fff;
  cursor: pointer;
  white-space: nowrap;
}

.table tr:not(:last-child) {
  border-bottom: 1px dotted #e0e0e0;
}

.table th {
  text-align: left;
  text-transform: uppercase;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  padding-right: 2.4rem;
}

.table td {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
  color: #0f0f1f;
  font-size: 1.4rem;
  font-weight: 500;
  white-space: nowrap;
  padding-right: 2.4rem;
}

.table td:first-child {
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.table td:last-child {
  padding-right: 1.2rem;
}

.trans-block {
  display: flex;
  align-items: center;
  gap: 1.8rem;

  text-transform: uppercase;
}

.trans {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
}

.trans--deposit {
  background-color: #f2f9ec;
}

.trans--withdrawal {
  background-color: #ffe3e3;
}

.trans ion-icon {
  display: block;
  width: 2rem;
  height: 2rem;

  transform: rotate(-45deg);
}

.trans--deposit ion-icon {
  color: #397305;
}

.trans--withdrawal ion-icon {
  color: #e03131;
}

.transaction-date-time {
  flex: 0 0 5rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;

  color: #828282;
}

.transaction-date {
  font-size: 1.6rem;
  color: #000;
  font-weight: 600;
}

.transaction-time {
  font-size: 1rem;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1.2rem;
  margin-top: 2.4rem;
}

.pagination button {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  border: 1px solid #397305;
  color: #397305;
  font-size: 1.8rem;
  font-family: inherit;
  background-color: transparent;
  cursor: pointer;
}

.pagination button.active,
.pagination button:hover {
  background-color: #397305;
  color: #fff;
}

.card {
  max-width: 50rem;
  margin: auto;
  background-color: #fff;
  border-radius: 0.6rem;
}

.card__heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 6px;
}

.card__text {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 4.2rem;
}

.card__info {
  background-color: #f4f4f4;
  border: 1px solid #bdbdbd;
  padding: 1.6rem;
  border-radius: 1.2rem;
  font-size: 1.4rem;
  margin-bottom: 3.6rem;
}

.card__block {
  padding: 3.6rem;
}

.amount-percentages {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.2rem;

  margin-top: 6px;
}

.amount-percentages span {
  background-color: #ffffff;
  border: 1px solid #eaeaea;
  color: #808080;
  height: 3.2rem;
  font-size: 1.2rem;
  border-radius: 6px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.amount-percentages span:hover {
  border: none;
  color: #fff;
  background-color: #397305;
}

.pin-block {
  background-color: #f2f5f0;
  padding: 3.6rem;

  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.pin-block__heading {
  text-align: center;
}

.pin-input-group {
  display: flex;
  justify-content: center;
  gap: 1.2rem;
}

.pin-input-group input {
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  border: 1px solid #bdbdbd;
  background-color: #fff;
  color: #000000;
  border-radius: 0.8rem;
  font-size: 1.4rem;
  text-align: center;
  font-family: inherit;
}

.pin-input-group input:focus {
  border: none;
  outline: 1px solid #397305;
}

.pin-block__footer {
  text-align: center;
}

.pin-block__footer a:link,
.pin-block__footer a:visited {
  text-decoration: none;
  color: #555;
  font-size: 1.4rem;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-image: url(./assets/images/auth_background.png);
  background-size: cover;

  display: flex;
  align-items: center;
  justify-content: center;

  z-index: 9999;
}

.logo-container {
  position: relative;
}

.logo-container::after {
  content: "";
  display: block;
  width: 8rem;
  height: 8rem;
  border-radius: 50%;
  background-color: #e8f8d8;
  z-index: -2;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container::before {
  content: "";
  display: block;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: #d5eebb;
  z-index: -1;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo-container img {
  display: block;
  height: 7rem;

  animation-name: glow;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes glow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}

.alert {
  position: relative;

  display: flex;

  border-radius: 6px;
  overflow: hidden;
  background-color: #fff;
}

.alert--dark {
  background-color: #f7f7f7;
}

.alert__icon-box {
  width: 4.8rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.alert__icon-box ion-icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  color: #fff;
}

.alert--success .alert__icon-box {
  background-color: #448c04;
}

.alert--danger .alert__icon-box {
  background-color: #ca0404;
}

.alert--warning .alert__icon-box {
  background-color: #fcc811;
}

.alert__message {
  flex: 1;

  padding: 1.2rem 2.4rem;
  font-size: 1.4rem;
  font-weight: 600;
}

.alert__close-block {
  padding-top: 0.8rem;
  padding-right: 1.2rem;
}

.alert__close {
  display: inline-flex;
  width: 2rem;
  height: 2rem;

  border-radius: 50%;
  background-color: #e5e5e5;
  cursor: pointer;

  align-items: center;
  justify-content: center;
}

.alert__close ion-icon {
  display: block;
  width: 1.4rem;
  height: 1.4rem;
  color: #4f4f4f;
}

.ben {
  margin-bottom: 1.2rem;
}

.ben__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.2rem;
}

.ben__heading {
  font-size: 1.8rem;
  font-weight: 700;
}

.ben__link {
  font-size: 16px;
  color: #448c04;
  cursor: pointer;
  display: inline-block;
  padding-bottom: 0.5px;
  border-bottom: 0.5px solid currentColor;
}

.beneficiaries {
  display: flex;
  align-content: center;
  gap: 1.2rem;

  overflow-x: scroll;
}

.beneficiaries::-webkit-scrollbar {
  display: none;
}

.beneficiary {
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;

  padding: 0.6rem 1.2rem;
  text-align: center;
  border-radius: 0.6rem;
  cursor: pointer;
  transition: all 0.2s;
  border: 1px solid transparent;
}

.beneficiary--active {
  border: 1px solid #448c04;
  background-color: #f7fdf2;
}

.beneficiary__name-logo {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;

  color: #fff;
  font-weight: 700;

  /* background-color: #4687d5; */
}

.beneficiary__name {
  font-weight: 600;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999999;
  cursor: pointer;
}

.deposit-modal {
  width: 90%;
  max-width: 60rem;
  background-color: #f7f9f6;
  border-radius: 0.8rem;
  overflow: hidden;
  z-index: 9999999999;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  padding: 5.6rem;

  display: flex;
  flex-direction: column;
  gap: 4rem;
}

@media (max-width: 600px) {
  .deposit-modal {
    padding: 3.6rem;
  }
}

@media (max-width: 400px) {
  .deposit-modal {
    gap: 2.4rem;
  }
}

.deposit-modal__header {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  text-align: center;
}

.deposit-modal__heading {
  font-size: 2.8rem;
  font-weight: 600;
  color: #5db013;
}

.deposit-modal__subheading {
  color: #141414;
  font-weight: 500;
}

.deposit-block {
  background-color: #ffffff;
  padding: 2.4rem 4.8rem;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  border-radius: 0.8rem;
}

@media (max-width: 600px) {
  .deposit-block {
    padding: 3.6rem;
  }
}

.deposit-block__heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;

  font-size: 2rem;
}

.deposit-block__heading img {
  display: block;
  width: 2rem;
  height: 2rem;
}

.deposit-block__account {
  height: 5.6rem;
  background-color: #f9faf8;
  border-radius: 6px;

  display: flex;
  align-items: center;
  gap: 1.2rem;

  padding: 1.2rem;
}

.deposit-block__account p {
  flex: 1;
  padding-left: 1.2rem;
}

.deposit-block__account button {
  align-self: stretch;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;

  padding: 0 1.2rem;
  border-radius: 4px;
  background-color: #7bb20e;
  color: #fff;
  font-family: inherit;
  border: none;
  cursor: pointer;
}

@media (max-width: 360px) {
  .deposit-block__account {
    flex-direction: column;
    height: auto;
  }

  .deposit-block__account button {
    padding: 1.2rem;
  }
}

.deposit-block__footer {
  font-size: 1.25rem;
  text-align: center;
  color: #737373;
}

.deposit-modal__footer {
  text-align: center;
}

.deposit-modal__button {
  font-family: inherit;
  cursor: pointer;
  text-align: center;
  background-color: #edeeec;
  border-radius: 4px;
  font-size: 1.5rem;
  font-weight: 600;
  border: none;

  width: 20rem;
  height: 4.8rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.modal {
  width: 90%;
  max-width: 40rem;
  background-color: #fff;
  border-radius: 3px;
  overflow: hidden;
  z-index: 9999999999;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*Select Crypto Modal*/
.modal-header,
.modal-body {
  background-color: #f9f9f9;
}

.modal-header {
  padding: 2.4rem 3.6rem;

  display: flex;
  align-items: center;
}

.modal-close {
  cursor: pointer;
}

.modal-close ion-icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
}

.modal-heading {
  flex: 1;

  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
}

.modal-body {
  padding: 1.2rem 3.6rem 4.8rem;
}

.search {
  height: 3.8rem;
  border: 1px solid #eaeaea;
  margin-bottom: 2.4rem;
  border-radius: 6px;

  display: flex;
  gap: 6px;
}

.search__icon-block {
  width: 3.8rem;

  display: flex;
  align-items: center;
  justify-content: center;
}

.search__icon-block ion-icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  color: #808080;
}

.search__input {
  flex: 1;
  background-color: transparent;
  font-family: inherit;
  font-size: 14px;
  color: #808080;
  font-weight: 600;
  margin-right: 12px;
  outline: 0;
  border: none;
}

.cryptocurrencies {
  height: 35vh;
  overflow-y: scroll;

  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

/* width */
.cryptocurrencies::-webkit-scrollbar {
  display: none;
  width: 5px;
}

/* Track */
.cryptocurrencies::-webkit-scrollbar-track {
  background: #f7f7f7;
}

/* Handle */
.cryptocurrencies::-webkit-scrollbar-thumb {
  background: #e2ecd8;
  border-radius: 10rem;
}

.crypto {
  padding: 1.2rem 2.4rem;
  cursor: pointer;
  border-radius: 6px;
  background-color: #fff;
  overflow: hidden;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.crypto__img-block {
  flex-shrink: 0;
}

.crypto__img-block img {
  width: 3.6rem;
  height: 3.6rem;
}

.crypto__divider {
  flex-shrink: 0;

  width: 1px;
  height: 1.6rem;
  background-color: #e0e0e0;
}

.crypto__main {
  flex: 1;

  display: flex;
  flex-direction: column;
}

.crypto__abbr {
  font-size: 1rem;
  color: #0f0f1f;
  text-transform: uppercase;
}

.crypto__name {
  font-size: 1.4rem;
  color: #0f0f1f;
  font-weight: 600;
  word-wrap: break-word;
}

.crypto__check {
  display: none;
}

.crypto:hover .crypto__check {
  display: block;
}

.crypto__check ion-icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  color: #448c04;
}

.crypto__name {
  font-size: 1.4rem;
}

.accept {
  display: flex;
  align-items: center;
  gap: 1.2rem;

  font-size: 1.6rem;
  font-weight: 500;
  color: #141414;
}

.accept__checkbox {
  flex-shrink: 0;
  appearance: none;
  cursor: pointer;
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  background-color: #aaaaaa;
  border-radius: 3px;
  position: relative;
}

.accept__checkbox:checked {
  background-color: #448c04;
}

.accept__checkbox::after {
  content: "\2713";
  display: block;
  color: #fff;
  font-weight: 700;
  font-size: 1.2rem;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: none;
}

.accept__checkbox:checked::after {
  display: block;
}

.deposit-details {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  margin-bottom: 2.4rem;
}

.deposit-detail {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.deposit-details__label {
  font-size: 12px;
  font-weight: 600;
}

.deposit-detail__amount {
  padding: 1.6rem;
  background-color: #f2f2f2;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  font-size: 1.4rem;
  color: #828282;

  display: flex;
  flex-direction: column;
  gap: 6px;
}

.main-card {
  max-width: 50rem;
  margin: 4.8rem auto 9.6rem;
  background-color: #fff;
  padding: 2.4rem;
}

.main-card__back {
  margin-bottom: 1.2rem;
  cursor: pointer;
}

.main-card__back ion-icon {
  display: block;
  width: 1.6rem;
  height: 1.6rem;
  color: #bdbdbd;
}

.main-card__heading {
  font-size: 1.8rem;
  text-align: center;
  font-weight: 700;
  margin-bottom: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.2rem;
}

.main-card__heading span {
  cursor: pointer;
}

.main-card__heading ion-icon {
  display: block;
}

.main-card__heading-subtext {
  font-size: 1.4rem;
  color: #828282;
  font-weight: 500;
  text-align: center;

  margin-bottom: 1.8rem;
}

.infos {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.info {
  padding: 1.2rem 1.8rem;
  background-color: #f9f9f9;

  display: flex;
  align-items: center;
  gap: 1.8rem;
}

.info__key {
  flex: 0 0 12rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  color: #828282;
}

.info__right {
  flex: 1;

  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.info__copy {
  margin-left: auto;

  display: flex;
  align-items: center;
  gap: 6px;

  background-color: #fff;
  padding: 4px 8px;
  border-radius: 6px;
  color: #000;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
}

.info__text {
  font-size: 1.4rem;
  font-weight: 600;
  word-break: break-all;
}

.info__date {
  font-size: 1.4rem;
  color: #828282;
}

.badge {
  display: inline-block;
  border-radius: 8px;
  padding: 4px 1.8rem;
  font-weight: 600;
  font-size: 1.4rem;
}

.badge--success {
  background-color: rgba(57, 115, 5, 0.1);
  color: #397305;
}

.badge--pending {
  background-color: rgba(255, 155, 0, 0.1);
  color: #ff9b00;
}

.badge--failed {
  background-color: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.badge--new {
  background-color: rgba(51, 155, 240, 0.1);
  color: #339af0;
}

.not-found {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.not-found__main {
  width: 130rem;
  max-width: 100%;
  position: relative;
  padding: 0 2.4rem;

  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.not-found__error-text {
  position: absolute;
  top: -1rem;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 9.6rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 800;
  color: #dddddd;
  z-index: -1;
}

.not-found__img-block {
  text-align: center;
}

.not-found__img {
  display: inline-block;
  height: 30rem;
  max-width: 100%;
}

.not-found__text {
  text-align: center;
}

.not-found__btn-group {
  display: flex;
  justify-content: center;
  gap: 2.4rem;
}

.not-found__btn {
  display: inline-block;
  background-color: #448c04;
  color: #fff;
  font-size: 1.4rem;
  border-radius: 6px;
  padding: 1.2rem 3.6rem;
  cursor: pointer;
  text-decoration: none;
}

@media (max-width: 500px) {
  .NotFound {
    padding: 36px;
  }

  .Img {
    height: auto;
    width: 100%;
  }
}

@media (max-width: 450px) {
  .BtnGroup {
    flex-direction: column;
  }
  .Btn {
    text-align: center;
  }
  .ErrorText {
    font-size: 64px;
  }

  .info {
    flex-direction: column;
    gap: 3px;
    align-items: center;
  }
  .info__key {
    flex: auto;
  }
  .info__right {
    align-items: center;
  }
}

@media (max-width: 1100px) {
  .wallet {
    padding: 0 6.4rem;
  }
}

@media (max-width: 900px) {
  .wallet {
    flex-direction: column;
    justify-content: center;
  }

  .wallet__heading {
    justify-content: center;
  }
}

@media (max-width: 600px) {
  .wallet__balance {
    font-size: 3.6rem;
  }
  .pay-btn {
    height: 7.6rem;
    padding: 0 3.6rem;
    font-size: 2.4rem;
  }
}

@media (max-width: 450px) {
  .txt-header {
    flex-direction: column;
    gap: 0.8rem;
  }
  .search-form {
    align-self: stretch;
    width: 100%;
  }

  /* .auth {
    padding: 0 1.8rem;
  }
  .auth__main {
    padding: 2.4rem 1.8rem;
  }
  .auth__img-block {
    margin-bottom: 0.6rem;
  }
  .auth__img {
    height: 4rem;
  }
  .auth__heading {
    font-size: 1.8rem;
  } */
  html {
    font-size: 50%;
  }
}

.file-drop {
  border: 1px dashed #448c04;
  border-radius: 0.8rem;
  height: 20rem;
  background-color: #fbfcfe;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.file-drop ion-icon {
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  margin-bottom: 1.8rem;
  color: #448c04;
}

.file-drop__info-1 {
  color: #262626;
  margin-bottom: 6px;
}

.file-drop__info-1 span {
  cursor: pointer;
  color: #448c04;
}

.file-drop__info-2 {
  font-size: 1.2rem;
  color: #9a9a9a;
}

.form-file {
  border-radius: 0.8rem;
  overflow: hidden;
  position: relative;
}

.form-file img {
  display: block;
  width: 100%;
}

.form-file span {
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 3.6rem;
  height: 3.6rem;
  background-color: #fa5252;
  border-radius: 50%;
  cursor: pointer;
}

.form-file span ion-icon {
  display: block;
  width: 1.8rem;
  height: 1.8rem;
  color: #fff;
}

.wallet-box {
  width: 100%;
  min-height: 32rem;
  border-radius: 2.4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2.4rem;
  padding: 0 9.6rem;
  color: #e6e6e6;
  position: relative;
}
@media (max-width: 950px) {
  .wallet-box {
    padding: 0 6.4rem;
  }
}
@media (max-width: 850px) {
  .wallet-box {
    padding: 0 4.8rem;
  }
}
@media (max-width: 650px) {
  .wallet-box {
    padding: 0 3.6rem;
    min-height: 28rem;
  }
}
@media (max-width: 650px) {
  .wallet-box {
    flex-direction: column;
    justify-content: initial;
    padding-top: 6.4rem;
    padding-bottom: 2.4rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}
.wallet-box__img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
  z-index: -1;
}
.wallet-box__main--primary {
  color: #448c04;
}
.wallet-box__header {
  display: flex;
  align-items: center;
  gap: 2.4rem;
  font-size: 1.6rem;
}
@media (max-width: 650px) {
  .wallet-box__header {
    justify-content: center;
  }
}
.wallet-box__show-hide {
  cursor: pointer;
}
.wallet-box__show-hide ion-icon {
  display: block;
}
.wallet-box__balance {
  font-size: 6.4rem;
  font-weight: 600;
  cursor: pointer;
}
@media (max-width: 815px) {
  .wallet-box__balance {
    font-size: 4.8rem;
  }
}

@media (max-width: 580px) {
  .wallet-box__balance {
    text-align: center;
  }
}

@media (max-width: 400px) {
  .wallet-box__balance {
    font-size: 3.2rem;
  }
}

.wallet-box__points {
  display: inline-block;
  width: 18rem;
  background-color: rgba(244, 250, 239, 0.13);
  border: 0.4px solid #e6e6e6;
  border-radius: 10rem;
  text-align: center;
  font-size: 1.8rem;
  padding: 8px 0;
  margin-top: 1.8rem;
  cursor: pointer;
  margin-left: 3.6rem;
}
@media (max-width: 580px) {
  .wallet-box__points {
    position: absolute;
    top: 1.2rem;
    right: 1.2rem;
    margin: 0;
    width: auto;
    padding: 8px 2.4rem;
    font-size: inherit;
  }
}
.wallet-box__points-2 {
  display: inline-block;
  background-color: #fff;
  border: 0.4px solid #448c04;
  color: #448c04;
  border-radius: 10rem;
  text-align: center;
  font-size: 2.4rem;
  font-weight: 500;
  padding: 1.2rem 4.8rem;
  margin-top: 1.8rem;
  cursor: pointer;
  font-family: inherit;
}
.wallet-box__actions {
  display: flex;
  gap: 6px;
}

.wallet-box-action {
  width: 12rem;
  height: 12rem;
  border-radius: 6px;
  color: #e6e6e6;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
@media (max-width: 850px) {
  .wallet-box-action {
    width: 10rem;
    height: 12rem;
  }
}
@media (max-width: 580px) {
  .wallet-box-action {
    height: 10rem;
  }
}
.wallet-box-action:hover {
  background-color: rgba(232, 252, 216, 0.07);
}
.wallet-box-action--active {
  border: 1px solid #e6e6e6;
  color: #244b01;
}
.wallet-box-action__icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  border-radius: 50%;
  background-color: #e6e6e6;
}
.wallet-box-action--active .wallet-box-action__icon-box {
  background-color: #244b01;
}
.wallet-box-action__icon {
  display: block;
  width: 2.4rem;
  height: 2.4rem;
  color: #448c04;
}

.info-grid {
  display: flex;
  gap: 2.4rem;

  margin-bottom: 4.8rem;

  overflow-x: scroll;
}

.info-grid::-webkit-scrollbar {
  display: none;
}

.info-grid > * {
  flex: 1;
}

@media (max-width: 600px) {
  .info-grid {
    display: grid;

    overflow: hidden;
  }
}

.wallet-info {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 2.4rem;

  border: 1px solid #e6e6e6;
  padding: 4rem 2.4rem;
  border-radius: 1rem;
}

.wallet-info--deposit {
  background-color: #f7fef1;
}

.wallet-info--withdraw {
  background-color: #fdf2f2;
}

.wallet-info__heading {
  font-weight: 500;
  font-size: 1.8rem;
  text-align: center;
}

.wallet-info--deposit .wallet-info__heading {
  color: #5db013;
}

.wallet-info--withdraw .wallet-info__heading {
  color: #df5454;
}

.wallet-info__main {
  display: flex;
  gap: 0.8rem;
}

.wallet-info__main > * {
  flex: 1;
}

@media (max-width: 600px) {
  .wallet-info__main {
    flex-wrap: wrap;
    justify-content: center;
  }

  .wallet-info__main > * {
    flex: initial;
  }
}

.single-info {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  text-align: center;
}

.single-info__value {
  padding: 1.2rem 0.8rem;
  background-color: #ffffff;
  border: 1px solid #f0f0f0;
  border-radius: 4px;
}

.single-info__key {
  color: #737373;
  font-size: 1.3rem;
  white-space: nowrap;
  padding: 0 1.2rem;
}

@keyframes loaderVBars {
  0% {
    top: 0.8rem;
    height: 6.4rem;
  }
  50%,
  100% {
    top: 2.4rem;
    height: 3.2rem;
  }
}

.loaderVBars-container {
  height: 4.2rem;
}

.loaderVBars {
  display: inline-block;
  position: relative;
  width: 8rem;
  height: 8rem;
}

.loaderVBars--sm {
  transform: scale(0.5) translateY(-50%);
}

.loaderVBars div {
  display: inline-block;
  position: absolute;
  left: 0.8rem;
  width: 1.6rem;
  background: #448c04;
  animation: loaderVBars 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.loaderVBars div:nth-child(1) {
  left: 0.8rem;
  animation-delay: -0.24s;
}

.loaderVBars div:nth-child(2) {
  left: 3.2rem;
  animation-delay: -0.12s;
}

.loaderVBars div:nth-child(3) {
  left: 5.6rem;
  animation-delay: 0;
}
